import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import biotechImg from '../../../../static/images/healthtech-page/other-sections/biotech.jpg'
import fintechImg from '../../../../static/images/healthtech-page/other-sections/fintech.jpg'
import powermanagementImg from '../../../../static/images/healthtech-page/other-sections/power-management.jpg'
import healthtechImg from '../../../../static/images/healthtech-page/other-sections/healthtech.jpg'


const OtherSectors = ({ hiddenIndex = 0 }) => {
    return (
        <OtherSectorsStyle>
            <H2>OTHER SECTORS</H2>
            <OtherSectorsLinks>
                {hiddenIndex !== 1 &&
                    <CategoryLink>
                        <Link to={'/sector/healthtech/'}>
                            <Image img={healthtechImg}/>
                            <H4>HEALTHTECH</H4>
                        </Link>
                    </CategoryLink>
                }
                {hiddenIndex !== 2 &&
                    <CategoryLink>
                        <Link to={'/sector/biotech/'}>
                            <Image img={biotechImg}/>
                            <H4>BIOTECH</H4>
                        </Link>
                    </CategoryLink>
                }
                {hiddenIndex !== 3 &&
                    <CategoryLink>
                        <Link to={'/sector/fintech/'}>
                            <Image img={fintechImg}/>
                            <H4>FINTECH</H4>
                        </Link>
                    </CategoryLink>
                }
                {hiddenIndex !== 4 &&
                    <CategoryLink>
                        <Link to={'/sector/power-management/'}>
                            <Image img={powermanagementImg}/>
                            <H4>POWER MANAGEMENT</H4>
                        </Link>
                    </CategoryLink>
                }
            </OtherSectorsLinks>
        </OtherSectorsStyle>
    )
}

export default OtherSectors

const OtherSectorsStyle = styled.div`
  padding: 14.7rem 12rem;
  display: flex;
  flex-direction: column;

  
  @media ${({theme}) => theme.breakpoints.mobile} {
    padding: 5rem 2.4rem;
    //padding: 0rem 12rem;
  }
  @media ${({theme}) => theme.breakpoints.small_height} {
    padding: 14.7rem 15vw;
  }
  a {
    transition: transform 100ms ease-in-out;
    transform-origin: center;
      :hover {
        transform: scale(1.07);
      }
  }
`

const H2 = styled.div`
  font-family: Sequel85;
  font-size: 3.2rem;
  line-height: 6rem;
  @media ${({theme}) => theme.breakpoints.mobile} {
    font-size: 2rem;
    line-height: 2.7rem;
  }
`

const OtherSectorsLinks = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    flex-wrap: unset;
    flex-direction: column;
  }
`

const CategoryLink = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
  a {
    width: 100%;
  }
`

const Image = styled.div`
  margin-top: 6.2rem;
  background-image: url(${props => props.img});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 39vh;
  height: 40vh;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    background-size: cover;
    margin-top: 4.2rem;
    width: 100%;
    height: 50vh;
    //height: unset;
  }
`

const H4 = styled.h4`
  margin-top: 3.5rem;
  font-family: Sequel55;
  font-size: 1.8rem;
  line-height: 2.4rem;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    margin-top: 2.5rem;
  }
`
