import React from 'react'
import styled from 'styled-components'

const Ultrasound = () => {
    return (
        <StyledUltrasound>
            <TextWrapper>
                <H4>The device accelerates two out of three critical steps of a traditional PCR test by harnessing the power of ultrasound in cell lysing and developing a new thermal PCR microchannels chip:</H4>
                <Categories>
                    <Ul>
                        <Li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <H4>RNA Extraction:
                                <br/>
                                <br/>
                                Medad Technology has explored cell lysing through ultrasound technology while the world was found short in supply of the RNA extraction chemicals amidst the Covid19 pandemic. At that time, we had already achieved very high precision in ultrasonic frequency for the inhalation technology in our HealthTech division. We have then repurposed the same technology, developing a proprietary Power Management Integrated Circuit (PMIC) to ensure efficient use of power to produce optimal sound waves for an ideal ultrasonic cell lysis and high precision RNA extraction.
                            </H4>
                        </Li>
                        <Li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <H4>
                                PCR:
                                <br/>
                                <br/>
                                The new thermal cycle chip reduces the cycle time to 1 second per cycle, completing the full thermal cycle process of 38 cycles to 38 seconds only. More content?
                            </H4>
                        </Li>
                    </Ul>
                    <H4></H4>
                    <H4></H4>
                </Categories>
            </TextWrapper>
        </StyledUltrasound>
    )
}

export default Ultrasound

const StyledUltrasound = styled.div`
  padding: 14.8rem 12rem 12.2rem 12rem;
  //min-height: 100vh;
  background-color: #BCDEE5;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    padding: 4rem 2.4rem 2.7rem 2.4rem;
  }
  @media ${({theme}) => theme.breakpoints.small_height} {
    padding: 14.8rem 15vw 12.2rem 15vw;
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Categories = styled.div`
  margin-top: 4rem;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    margin-top: 2rem;
  }
`

const H4 = styled.h4`
  font-family: Sequel45;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.4rem;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    font-family: Sequel45;
    font-size: 1.6rem;
    line-height: 2.2rem;
  }
`

const Ul = styled.ul`
  display: flex;
  flex-direction: row;
  gap: 10.5rem;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    flex-direction: column;
    gap: 2rem;
  }
`

const Li = styled.li`
  flex: 1;
  list-style: none;
  display: flex;
  flex-direction: row;
  
  &::before {
    content: '\\00b7';
    font-size: 2.4rem;
  }
`
