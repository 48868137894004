import React from 'react'
import styled from 'styled-components'
import {Link} from "gatsby";

const Screening = () => {
    return (
        <StyledScreening>
            <TextWrapper>
                    <H3>Medad Technology is developing a quick, reliable and an all-in-one infectious disease screening device.</H3>
                <Distance>
                    <H4>Medad Technology's Extreme RT PCR is an all-in-one benchtop device. It produces test results within 3 minutes, with 98% sensitivity and 100% specificity, registering the fastest gold standard method for virus detection, operating at the accuracy of a PCR test but at the speed of an antigen test. For the first time, the gap between testing and result is being closed.</H4>
                    <H4>The device accelerates 2 out of 3 critical steps of a traditional PCR test by harnessing the power of ultrasound in cell lysing and developing a new thermal PCR microchannel chip. It also uses a proprietary specific master-mix and a One-Time Programmable chip as an anticounterfeit measure allowing only genuine disposable thermal chips to be used.</H4>
                    {/*<H4>Medad Technology was successfully granted 4 <Link to={'https://patents.justia.com/patent/11181451'} target={'_blank'}><Span>patents</Span></Link> from the United States Patent and Trademark Office (USPTO) for our innovations in Extreme RT-PCR.</H4>*/}
                </Distance>
            </TextWrapper>
            {/*<Background />*/}
        </StyledScreening>
    )
}

export default Screening

const StyledScreening = styled.div`
  position: relative;
  padding: 9.4rem 12rem 7rem 12rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0C2637;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    padding: 6rem 2.4rem 3.8rem 2.4rem;
  }
  @media ${({theme}) => theme.breakpoints.small_height} {
    padding: 9.4rem 15vw 7rem 15vw;
  } 
`

const TextWrapper = styled.div`
    text-align: left;
`

const Distance = styled.div`
    margin-top: 4rem;
`

const H3 = styled.h3`
  font-family: Sequel75;
  font-size: 3.2rem;
  line-height: 4.4rem;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    font-size: 1.6rem;
    line-height: 2.2rem;
  }
`

const H4 = styled.h4`
  margin-top: 1.6rem;
  font-family: Sequel45;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.4rem;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    margin-top: 2rem;
    font-family: Sequel45;
    font-size: 1.6rem;
    line-height: 2.2rem;
  }
`

const Span = styled.span`
  color: #3B869D;
  text-decoration: underline solid #3B869D .3rem;
  text-underline-offset: .3rem;
  font-size: 1.8rem;
  font-family: Sequel55;
`



