import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import Modal from 'react-modal'

import buttonImg from '../../../static/images/modals/images/close.svg'
import * as Yup from "yup";
import Input from "./Input";
import ButtonStyled from "./Button";
import {navigate} from "gatsby";
import {Formik} from "formik";

import Contact from "../contactus-page/contact/Contact";

let customStyles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgb(0, 31, 45, 0.8)',
        zIndex: 20,
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '65%',
        padding: '0px',
        borderRadius: '2px',
        height: '100%',
        marginTop: '5vh',
    }
};

Modal.setAppElement('body')

const ModalStayUpdated = ({modalState, setModalState}) => {

        function closeModal() {
            setModalState(false);
            document.body.style.height = "unset";
            document.body.style.overflowY = "unset";
        }

        return (
            <ModalStayUpdatedStyle>
                <Modal
                    isOpen={modalState}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel={'contact-us'}
                    shouldCloseOnOverlayClick={true}
                    className="modal-stay-updated"
                >
                    <PaddingModal>
                        <CloseModal onClick={() => closeModal()}>
                            <ButtonImage/>
                        </CloseModal>
                        <Contact isModal/>
                    </PaddingModal>
                </Modal>
            </ModalStayUpdatedStyle>
        )
    }

    export default ModalStayUpdated

    const ModalStayUpdatedStyle = styled.div`
      position: relative;
      @media ${({theme}) => theme.breakpoints.mobile} {

        overflow-y: auto;
        //padding-left: 1.4rem;
        //padding-right: 1.4rem;
      }
    `

    const CloseModal = styled.button`
      border: none;
      position: absolute;
      top: 4rem;
      right: 4rem;
      background: none;
      @media ${({theme}) => theme.breakpoints.mobile} {
        top: 2rem;
        right: 1rem;
      }
    `

    const ButtonImage = styled.div`
      background-image: url(${buttonImg});
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      width: 2.4rem;
      height: 2.4rem;
    `

    const Wrapper = styled.div`
    `

    const PaddingModal = styled.div`
      height: 100%;
        // @media ${({theme}) => theme.breakpoints.mobile} {
      //   padding-top: 4rem;
      //   padding-left: 2.4rem;
      //   padding-right: 2.4rem;
      //   padding-bottom: 4rem;
      // }
    `