import React, {useState} from 'react'
import styled from 'styled-components'
import {Link, navigate} from 'gatsby'
import {Formik, Form, Field} from "formik"
import * as Yup from "yup"
import ButtonStyled from "../../library/Button";
import Input from '../../library/Input'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import TextArea from "../../library/TextArea";
import {contactUs} from "../../../services/contact-us.js";

const Contact = ({isModal = false}) => {
    const [result, setResult] = useState(null)

    const submitForm = async (values) => {
        try {
            const val = {
                name: values.fullName,
                topic: values.subject,
                email: values.email,
                message: `Company name: ${values.companyName}   Message: ${values.yourMessage}`
            }
            await contactUs(val);
            setResult("success");
            // navigate('/confirmation-newsletter')
        } catch (e) {
            console.log("E", e);
            setResult("error");

            // if (e.code === 6)
            //     setError(dataCockpit.cockpitFooter.alreadySigned.value);
            // else
            //     setError("Error, please try again later");
        }
    }

    return (
        <ContactStyle isModal={isModal}>
            <MailWrapper>
                <Mail>
                    <H3>We would love<br/> to hear from you</H3>
                    <P>Fill in the form to contact us,
                        or send us an email.</P>
                    <LinkMail><a
                        href="mailto:info@medadtechnology.com"><span>info@medadtechnology.com</span></a></LinkMail>
                </Mail>
            </MailWrapper>
            <FormikWrapper>
                <Formik
                    enableReinitialize
                    initialValues={{
                        subject: 'INFO',
                        fullName: '',
                        email: '',
                        companyName: '',
                        yourMessage: ''
                    }}
                    validationSchema={Yup.object({
                        fullName: Yup.string()
                            .required("Field required"),
                        companyName: Yup.string()
                            .required("Field required"),
                        yourMessage: Yup.string()
                            .required("Field required"),
                        subject: Yup.string()
                            .required("Field required"),
                        email: Yup.string()
                            .email("Must be an email")
                            .required("Field required"),
                    })}
                    onSubmit={(values, {setSubmitting}) => {
                        submitForm(values);
                    }}
                >

                    {props => (
                        <Form>
                            <FormWrapper isModal={isModal}>
                                <Field as="select" name="subject" id="select-subject">
                                    <option value="INFO" selected>General enquiry</option>
                                    <option value="SUPPORT">Support</option>
                                    <option value="OTHERS">Others</option>
                                </Field>
                                <Input name={'fullName'} id={'fullName'} placeholder={'Full name*'} type={'text'}/>
                                <Input name={'email'} id={'email'} placeholder={'Email*'} type={'email'}
                                       className={'email'}/>
                                <Input name={'companyName'} id={'companyName'} placeholder={'Company name'}
                                       type={'text'}
                                       className={'company'}/>
                                {/*<Input name={'yourMessage'} id={'yourMessage'} placeholder={'Your message'} type={'text'}*/}
                                {/*       className={'message'}/>*/}
                                <TextArea placeholder={'Your message'} name={'yourMessage'} id={'yourMessage'}
                                          rows={'4'}/>
                                <ButtonWrapper>
                                    {result === "success" &&
                                        <Success><p>Thank you, message sent !</p></Success>
                                    }
                                    {result === "error" &&
                                        <Success><p>Good news, your email is already registered!</p></Success>
                                    }
                                    <div></div>
                                    <ButtonStyled primary type={'submit'} label={'Submit'}/>
                                </ButtonWrapper>
                            </FormWrapper>
                        </Form>
                    )}
                </Formik>
            </FormikWrapper>
        </ContactStyle>
    )
}

export default Contact

const ContactStyle = styled.div`
  //padding-top: 9.2rem;
  //padding-left: 12rem;
  //padding-right: 12rem;
  //padding-bottom: 9.2rem;
  padding-top: ${({isModal}) => isModal ? '5.6rem' : '9.2rem'};
  padding-bottom: ${({isModal}) => isModal ? '5.6rem' : '9.2rem'};
  padding-right: ${({isModal}) => isModal ? '9.4rem' : '12rem'};
  padding-left: ${({isModal}) => isModal ? '6rem' : '12rem'};

  display: flex;
  justify-content: space-between;
  gap: ${({isModal}) => isModal ? '55px' : 'none'};
  align-items: flex-start;
  @media ${({theme}) => theme.breakpoints.mobile} {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
    padding-bottom: 3.5rem;

    padding-top: ${({isModal}) => isModal ? '40px' : '90px'};
    padding-bottom: ${({isModal}) => isModal ? '40px' : '35px'};
    padding-right: ${({isModal}) => isModal ? '14px' : '24px'};
    padding-left: ${({isModal}) => isModal ? '14px' : '24px'};

    gap: ${({isModal}) => isModal ? '30px' : 'none'};

    flex-direction: column;
    justify-content: center;
    align-items: center;
    form {
      width: 100%;
    }
  }
  @media ${({theme}) => theme.breakpoints.small_height} {
    padding: 9.2rem 9.4rem 9.2rem 6rem;
    gap: 22rem;
  }
`

const MailWrapper = styled.div`
  flex: 1;
  @media ${({theme}) => theme.breakpoints.mobile} {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }
`

const Success = styled.div`
  display: flex;
  align-items: center;
  margin-right: 3rem;
  padding: 1rem 0rem;
  color: #53976F;
`

const Error = styled.div`
  display: flex;
  align-items: center;
  margin-right: 3rem;
  padding: 1rem 0rem;
  color: #DF3B3B;
`

const Mail = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`

const H3 = styled.h3`
  max-width: 34.9rem;
  font-family: Sequel75;
  font-size: 3.2rem;
  line-height: 5.4rem;
  @media ${({theme}) => theme.breakpoints.mobile} {
    font-size: 2rem;
    line-height: 2.8rem;
    br {
      display: none;
    }
  }
`

const P = styled.p`
  margin-top: 2.6rem;
  max-width: 34.2rem;
  font-family: Sequel45;
  font-weight: normal;
  font-size: 2rem;
  line-height: 2.7rem;
`

const LinkMail = styled.div`
  font-family: Sequel45;
  font-size: 2rem;
  line-height: 2.6rem;
  margin-top: .7rem;

  span {
    color: #3B869D;
  }
`


const FormikWrapper = styled.div`
  flex: 1;
  width: 100%;
`

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({isModal}) => isModal ? 'auto' : '53.8rem'};

  #select-subject {
    //-webkit-appearance: none;
    //-webkit-appearance: menulist;
    //-moz-appearance: none;
    //appearance: none;

    //-webkit-border-radius: 3px;

    padding-top: 1.4rem;
    padding-bottom: 2rem;
    border: none;
    outline: none;
    font-family: Sequel45;
    font-size: 1.6rem;
    line-height: 2.8rem;
    color: #959595;
    //-webkit-border: none;
    //-webkit-border-bottom: .1rem solid #B8B8B8;
    //-webkit-box-shadow: none;
    //-moz-box-shadow: none;
    //-webkit-text-decoration-style: none;
    //box-shadow: none;
    border-bottom: .1rem solid #B8B8B8;
  }


  @media ${({theme}) => theme.breakpoints.mobile} {
    //margin-top: 7.5rem;
    #select-subject {
      padding-top: 0;
    }

    margin-top: ${({isModal}) => isModal ? '3rem' : '6rem'};
    width: 100% !important;
  }
`

const ButtonWrapper = styled.div`
  margin-top: 4rem;
  display: flex;
  justify-content: space-between;
  @media ${({theme}) => theme.breakpoints.mobile} {
    flex-direction: column;
  }
`
