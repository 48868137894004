import React from 'react'
import styled from 'styled-components'

import sonochemistryImg from '../../../../static/images/healthtech-page/sonochemistry/sonochemistry.svg'

const Sonochemistry = () => {
    return (
        <SonochemistryStyle>
            <Description>
                <Alignment>
                    <H3>medad technology has adapted the concept of sonochemistry to create an innovative and compact
                        new nebulizer that completely eradicates the inaccuracy of drug delivery due to droplet size,
                        and that creates a safer alternative for tobacco delivery.</H3>
                    <P>Medad Technology has developed a miniaturized hand-held nebulizer with a suite of companion
                        integrated chips capable of operating ultrasound transducers at high frequencies with feedback
                        control algorithms. Working on the principles of piezoelectric effect and ultrasound cavitation,
                        the nebulizer enables the aerosolization of dense and viscous solutions, creating a fine
                        mist.</P>
                    <P className={'second-paragraph'}>The inhalation technology is the 1st technology that MT started with 3 years ago. To date, Medad
                        Technology has invested US$50 million in R&D, in-house manufacturing, product testing, consumer
                        research, and securing regulatory approval of its ultrasound technology in the UAE and
                        international markets. And we plan to spend another US$100 million on smoking cessation research
                        over the next three years, as well as studying the potential applications of its technology in
                        the healthcare sector and other industries.</P>
                </Alignment>
            </Description>
            <Image/>
        </SonochemistryStyle>
    )
}

export default Sonochemistry

const SonochemistryStyle = styled.div`
  min-height: 100vh;
  margin: 21rem 0;
  display: flex;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    flex-direction: column;
    margin-top: 0;
    margin-bottom: 0;
  }
`

const Description = styled.div`
  flex: 1;
  
  @media ${({ theme }) => theme.breakpoints.mobile} {
    flex: unset;
    padding: 0 2.4rem;
    margin-top: 17.2rem;
    margin-bottom: 17.2rem;
  }
  
  @media ${({ theme }) => theme.breakpoints.mobile} {
    padding: 0 2.4rem;
    margin-top: 8.6rem;
    margin-bottom: 4rem;
  }
`

const Alignment = styled.div`
  position: sticky;
  top: 15rem;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    .second-paragraph {
      display: none;
    }
  }
`

const Image = styled.div`
  flex: 0.8;
  background-image: url(${sonochemistryImg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
  
  @media ${({ theme }) => theme.breakpoints.mobile} {
    flex: unset;
    width: 100%;
    height: 100vh;
  }
`

const H3 = styled.h3`
  max-width: 55.7rem;
  font-family: Sequel75;
  font-size: 2rem;
  line-height: 2.7rem;
`

const P = styled.p`
  max-width: 55.7rem;
  font-family: Sequel45;
  margin-top: 4rem;
  font-size: 2rem;
  line-height: 2.7rem;
`
