import React, {useState} from 'react'
import styled from 'styled-components'
import {Link} from 'gatsby'
import ModalFormContact from "../../library/ModalFormContact";

const Applications = () => {
    const [modalState, setModalState] = useState(false)


    return (
        <StyledApplications>
            <TextWrapper>
                <H2>APPLICATIONS</H2>
                <Categories>
                    <Ul>
                        <Li>
                            <H3>Extreme RT-PCR Testing</H3>
                            <P>Medad Technology has designed and developed an effective all-in-one Extreme RT PCR testing device built around ultrasonic cell lysis for RNA extraction, and reduced thermal cycle time. The device registers 98% sensitivity and 100% specificity and, for the first time, closes the gap between testing and result time to providing diagnostics in less than 5 minutes. It is highly customizable for its throughputs and types of viruses detected.</P>
                        </Li>
                        <Li>
                            <H3>Technology Licensing</H3>
                            <P>The high-precision ultrasonic cell lysis technology as well as the newly-developed thermal cycle chip are each a stand-alone product that can be embedded in existing infectious diseases screening devices, enabling a high precision extraction of intracellular components as well as an exponentially faster PCR phase. <ModalButton onClick={() => setModalState(true)}>Contact us</ModalButton> for
                                technology licensing.</P>
                        </Li>
                    </Ul>
                </Categories>
            </TextWrapper>
            <ModalFormContact modalState={modalState} setModalState={setModalState} />
        </StyledApplications>
    )
}

export default Applications

const StyledApplications = styled.div`
  padding: 7rem 12rem 7.7rem 12rem;
  background-color: #E7F0F3;
  color: #0C2637;
  @media ${({theme}) => theme.breakpoints.mobile} {
    padding: 4.5rem 2.4rem 3.5rem 2.4rem;
  }
  @media ${({theme}) => theme.breakpoints.small_height} {
    padding: 7rem 15vw 7.7rem 15vw;
  } 
`

const TextWrapper = styled.div`

`
const H2 = styled.h2`
  font-family: Sequel85;
  font-size: 3.2rem;
  line-height: 5.8rem;
  @media ${({theme}) => theme.breakpoints.mobile} {
    font-family: Sequel75;
    font-size: 2rem;
    line-height: 2.8rem;
  }
`

const Categories = styled.div`
`

const Ul = styled.ul`
  list-style: none;
  margin-top: 6rem;
  display: flex;
  flex-direction: row;
  gap: 16.4rem;
  @media ${({theme}) => theme.breakpoints.mobile} {
    margin-top: 4.7rem;
    flex-direction: column;
    gap: 0;
  }
`

const Li = styled.li`
  flex: 1;
  @media ${({theme}) => theme.breakpoints.mobile} {
    &:last-child {
      margin-top: 4rem;
    }
  }
`

const H3 = styled.h3`
  font-family: Sequel75;
  font-size: 2rem;
  line-height: 2.8rem;
  color: #3B869D;
`

const P = styled.p`
  font-family: Sequel45;
  font-size: 1.6rem;
  line-height: 2.2rem;
  margin-top: 2rem;
`

const ModalButton = styled.button`
  cursor: pointer;
  font-family: Sequel45;
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: #3B869D;
  text-decoration: none;
  background: none;
  border: none;
`
