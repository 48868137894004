import Lottie from "lottie-react";
import React from 'react'
import styled from 'styled-components'
import { graphql } from "gatsby";

import bg from '../../../../static/images/biotech-page/hero/images/hero.jpg'
import logo from '../../../../static/images/biotech-page/hero/images/logo.svg'
import scroll from "../../../../static/images/hero/image/Scroll.json";
import MenuLeft from "../../menu-left/MenuLeft.js"

const Hero = ({path}) => {

    return (
        <>
            <HeroStyle>
                <DisplayToggle>
                    <MenuLeft path={path}/>
                </DisplayToggle>
                <PageTitle>Where we focus</PageTitle>
                <Title>
                    <LottieWrapper>
                        <Lottie id="lottie-loading" className="lottie-loading" animationData={scroll} loop={true} autoPlay={true}/>
                    </LottieWrapper>
                    <h1>
                        BIOTECH
                    </h1>
                </Title>
            </HeroStyle>
        </>
    )
}

const PageTitle = styled.p`
    color: #E7F0F3;
    align-self: center; 
    @media ${({theme}) => theme.breakpoints.mobile_reverse} {
        display: none;
    }
`

const DisplayToggle = styled.div`
  @media ${({theme}) => theme.breakpoints.mobile} {
    display: none;
  }
`

const HeroStyle = styled.div`
  padding: 10rem 12rem 6rem 12rem;
  min-height: calc(100vh);
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  gap: 2rem;
  flex-direction: column;
  color: ${({theme}) => theme.colors.white};
  justify-content: space-between;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    background-position: center;
    padding: 0rem 2.4rem;
    justify-content: center;
  }
  // @media ${({theme}) => theme.breakpoints.small_height} {
  //   padding: 10rem 15vw 6rem 15vw;
  // } 
`

const LottieWrapper = styled.div`
    #lottie-loading {
        z-index: 1;
        position: absolute;
        left: 105px;
        bottom: 50px;
        max-height: 150px;
        width: 40px;
        svg {
            height: unset;
        }
        @media ${({theme}) => theme.breakpoints.mobile} {
            bottom: 0;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            position: absolute;
            width: 25px;
        }
        // @media ${({theme}) => theme.breakpoints.small_height} {
        //     left: calc(15vw - 2.3rem);
        // }            
    }
    @media ${({theme}) => theme.breakpoints.mobile} {
        align-self: center;
    }
`


const Title = styled.div`
  align-self: flex-end;  
  @media ${({ theme }) => theme.breakpoints.mobile} {
      align-self: center; 
      h1 {
        font-size: 2.6rem;
        line-height: 3.6rem;
        text-align: center;
      } 
  }
`

export default Hero

const query = graphql`
    query {
        strapiSectors {
            title
            description
        }
    }
`;
