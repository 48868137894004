import React from 'react'
import styled from 'styled-components'

import securityImg from '../../../../static/images/healthtech-page/security/security.svg'
import securityMobileImg from '../../../../static/images/healthtech-page/security/security-mobile.svg'


const Security = () => {
    return (
        <SecurityStyle>
            <Description>
                <TextWrapper>
                    <H2>APPLICATIONS <span>SECURITY</span></H2>
                    <P>The cutting-edge delivery system has an integrated counterfeit protection feature based on a One-Time Programmable (OTP) chip authenticating the Sonic Engine to the Aerosol Chamber.</P>
                </TextWrapper>
            </Description>
            <Image/>
        </SecurityStyle>
    )
}

export default Security

const SecurityStyle = styled.div`
  min-height: 100vh;
  padding: 10rem 12rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15.1rem;
  flex-wrap: wrap;
  @media ${({theme}) => theme.breakpoints.mobile} {
    flex-direction: column-reverse;
    gap: 0;
    padding: 0;
  }
  @media ${({theme}) => theme.breakpoints.small_height} {
    padding: 0 15vw 0 15vw;
  }
`

const Description = styled.div`
  flex: 1;
  @media ${({theme}) => theme.breakpoints.mobile} {
    flex: unset;
    // margin-bottom: 6.3rem;
  }
`

const TextWrapper = styled.div`
    @media ${({ theme }) => theme.breakpoints.mobile} {
      padding: 0 2rem;
      margin-top: 5rem;
    }
`

const H2 = styled.h2`
  font-family: Sequel85;
  font-size: 3.2rem;
  line-height: 4.4rem;
  text-transform: uppercase;
  span {
    display: block;
  }
  @media ${({ theme }) => theme.breakpoints.mobile} {
    text-transform: unset;
    font-family: Sequel75;
    font-size: 2rem;
    line-height: 2.7rem;
  }
`

const P = styled.p`
  margin-top: 4.3rem;
  font-family: Sequel45;
  max-width: 53.8rem;
  line-height: 2.4rem;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    margin-top: 2rem;
    min-width: 27.2rem;
    line-height: 2.2rem;
  }
`

const Image = styled.div`
  flex: 0.8;
  background-image: url(${securityImg});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 60vh;
  min-width: 44.8rem;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    background-image: url(${securityMobileImg});
    flex: unset;
    background-size: cover;
    width: 100%;
    min-width: unset;
  }
`
