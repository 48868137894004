import React from 'react';
import styled from 'styled-components'
import {useField} from "formik";

const Input = (props) => {

    const {id, name, placeholder, ...rest} = props;
    const [field, meta] = useField(props)

    return (
        <TextAreaWrapper>
            {/*{label && <Label htmlFor={id}>{label}</Label>}*/}
            <StyledTextArea
                name={name}
                id={id}
                placeholder={placeholder}
                {...field}
                {...rest}>
            </StyledTextArea>
            {meta.touched && meta.error ? (
                <Error>
                    {meta.error}
                </Error>
            ) : null}
        </TextAreaWrapper>
    );
};

export default Input;

const TextAreaWrapper = styled.div`
  padding-top: 3.2rem;
  cursor: pointer;
`

const Label = styled.label`
  font-family: Sequel45;
  font-size: 1.6rem;
  font-weight: 400;
  color: #959595;
`

const StyledTextArea = styled.textarea`
  resize: none;
  cursor: pointer;
  width: ${props => props.width ? `${props.width}` : "100%"};
  font-family: ${({theme}) => theme.fonts.sequel45};
  font-size: 1.6rem;
  border: none;
  border-bottom: 1px solid #B8B8B8;
  //padding: 2rem 0rem;
//  margin-top: 1.2rem;
  outline: none;
  ${({theme, icon}) => {
    if (icon) {
      return (theme.icons[icon])
    }
  }};
  background-color: transparent;
  background-position: right ${({theme}) => theme.spacings.medium} center;

  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #959595;
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #959595;
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
    color: #959595;
  }

  @media ${({theme}) => theme.breakpoints.mobile} {
    width: 100%;
  }
`

const Error = styled.p`
  font-family: Sequel45;
  font-size: 1.6rem;
  color: #DF3B3B;
`
