import { graphql, useStaticQuery } from "gatsby";
import React from 'react'

import Hero from "../../components/biotech-page/hero/Hero.js";
import Layout from '../../components/layout/Layout'
import Seo from '../../components/seo'
import Sonochemistry from "../../components/healthtech-page/sonochemistry/Sonochemistry";
import Applications from "../../components/biotech-page/applications/Applications";
import Security from "../../components/healthtech-page/security/Security";
import OtherSectors from "../../components/healthtech-page/other-sectors/OtherSectors";
import Screening from "../../components/biotech-page/screening/Screening";
import Ultrasound from "../../components/biotech-page/ultrasound/Ultrasound";

const BiotechPage = (props) => {
    const {strapiBiotech} = useStaticQuery(query);
    return (
        <Layout>
            <Seo
                title={strapiBiotech.metaTitle}
                description={strapiBiotech.metaDescription}
                keywords={strapiBiotech.metaKeywords}
            />
            <Hero path={props.location.pathname}/>
            <Screening />
            {/*<Ultrasound />*/}
            <Applications />
            <OtherSectors hiddenIndex={2} />
        </Layout>
    )
}

export default BiotechPage

const query = graphql`
    query {
        strapiBiotech {
            metaTitle
            metaDescription
            metaKeywords
        }
    }
`
